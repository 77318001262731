var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-card-code',{attrs:{"title":"Data Penugasan"}},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"NIP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"NIP","label-for":"employee_nip","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"employee_nip","reduce":function (employee_nip) { return employee_nip.nip; },"placeholder":"Pilih Pegawai","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listPegawai,"label":"name"},model:{value:(_vm.dataParent.employee_nip),callback:function ($$v) {_vm.$set(_vm.dataParent, "employee_nip", $$v)},expression:"dataParent.employee_nip"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"position_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Jabatan","label-for":"position_id","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"position_id","reduce":function (position_id) { return position_id.real_id; },"placeholder":"Pilih Posisi","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listPosition,"label":"name"},model:{value:(_vm.dataParent.position_id),callback:function ($$v) {_vm.$set(_vm.dataParent, "position_id", $$v)},expression:"dataParent.position_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Tanggal Mulai","label-for":"start_date"}},[_c('validation-provider',{attrs:{"name":"Tanggal Mulai","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"start_date","type":"date","state":errors.length > 0 ? false : null,"placeholder":"21-05-1992"},model:{value:(_vm.dataParent.start_date),callback:function ($$v) {_vm.$set(_vm.dataParent, "start_date", $$v)},expression:"dataParent.start_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tanggal Selesai","label-for":"end_date"}},[_c('validation-provider',{attrs:{"name":"Tanggal Selesai","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"end_date","type":"date","state":errors.length > 0 ? false : null,"placeholder":"21-05-1992"},model:{value:(_vm.dataParent.end_date),callback:function ($$v) {_vm.$set(_vm.dataParent, "end_date", $$v)},expression:"dataParent.end_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"SK Penugasan","label-for":"decree"}},[_c('validation-provider',{attrs:{"name":"SK Penugasan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"decree","state":errors.length > 0 ? false : null,"placeholder":"SK Penugasan"},model:{value:(_vm.dataParent.decree),callback:function ($$v) {_vm.$set(_vm.dataParent, "decree", $$v)},expression:"dataParent.decree"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.formSubmitted}},[_vm._v(" Submit ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function () { return _vm.$router.go(-1); }}},[_vm._v(" Reset ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }